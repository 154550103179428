import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { useAppSelector } from '@/store/hooks';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

import { PopularProducts } from '@/entities/product';

import { errorsPageNumbers } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';
import { MenuBookIcon } from '@/shared/ui/icons';
import { Layout } from '@/shared/ui/layout';
import { PageSection } from '@/shared/ui/page-section';

import { StyledErrorPage, StyledErrorPageText, StyledErrorPageTitle } from './styled';

type TProps = {
    errorType: 404 | 500;
};

export const ErrorPage: FC<TProps> = ({ errorType = 404 }) => {
    const { t } = useTranslate();

    const router = useRouter();

    const popularProducts = useAppSelector((state) => state.rent.popularProducts.data);

    const cityUser = useAppSelector((state) => state.user.cityUser);

    return (
        <Layout header={<Header />} footer={<Footer />}>
            <Layout.Content>
                <StyledErrorPage>
                    <div className="smile-block">
                        <Image src="/static/icons/thoughtful_smile.png" alt="thoughtful smile" width={86} height={90} />
                        <Image src="/static/icons/thoughtful_smile.png" alt="thoughtful smile" width={59} height={59} />
                        <Image src="/static/icons/monocol_smile.png" alt="monocol smile" width={46} height={49} />
                        <Image src="/static/icons/thoughtful_smile.png" alt="thoughtful smile" width={59} height={59} />
                        <Image src="/static/icons/monocol_smile.png" alt="monocol_smile" width={49} height={49} />
                    </div>
                    <StyledErrorPageTitle> {errorType} </StyledErrorPageTitle>
                    <StyledErrorPageText> {errorsPageNumbers[errorType]()} </StyledErrorPageText>
                    <Button
                        variant="dark"
                        onClick={() => router.replace(`/${cityUser?.slug}`)}
                        fontSize="md"
                        weight="700"
                        aria-label="К каталогу"
                        icon={<MenuBookIcon />}
                    >
                        {t('К каталогу')}
                    </Button>
                </StyledErrorPage>
                <PageSection title="Самые популярные 🔥">
                    <PopularProducts products={popularProducts} />
                </PageSection>
            </Layout.Content>
        </Layout>
    );
};
