import { Col, Row } from 'antd';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { TRentProduct, ProductCard } from '@/entities/product';

import { TMeta } from '@/shared/api';
import { SIZE_SHOWN_PRODUCTS } from '@/shared/constants/settings';
import { PaginationAnt } from '@/shared/ui/pagination';

import { StyledCategoryProducts } from './styled';

type TProps = {
    products: TRentProduct[];
    metaProducts: TMeta;
};

export const CategoryProducts: FC<TProps> = ({ products = [], metaProducts }) => {
    const router = useRouter();

    return (
        <StyledCategoryProducts>
            <div>
                <Row gutter={[16, 16]}>
                    {products.map((product, index) => (
                        <Col key={index} span={24} lg={6} md={8} sm={12}>
                            <ProductCard product={product} className="card" />
                        </Col>
                    ))}
                </Row>
            </div>
            {metaProducts.totalItems > metaProducts.itemsPerPage && (
                <div className="pagination-block">
                    <PaginationAnt
                        isDisabledNavBut
                        router={router}
                        isSeo={true}
                        pageSizeItem={SIZE_SHOWN_PRODUCTS}
                        currentPage={metaProducts.currentPage}
                        total={metaProducts.totalItems}
                        typePagination="query"
                    />
                </div>
            )}
        </StyledCategoryProducts>
    );
};
