import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FC, ReactNode, useState } from 'react';

import { useAppSelector } from '@/store/hooks';

import { PopularProducts } from '@/entities/product';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';
import { MenuBookIcon } from '@/shared/ui/icons';
import { PageSection } from '@/shared/ui/page-section';

const SearchProductsInput = dynamic(() => import('../../modules/search-products-input'), { ssr: false });

import {
    StyledSearchingEmpty,
    StyledSearchingEmptyInput,
    StyledSearchingEmptySmile,
    StyledSearchingEmptySubtitle,
    StyledSearchingEmptyTitle,
} from './styled';

type TProps = {
    customTitle?: string;
    customSubtitle?: ReactNode;
};

export const SearchingEmpty: FC<TProps> = ({ customTitle, customSubtitle }) => {
    const router = useRouter();

    const { t } = useTranslate();

    const popularProducts = useAppSelector((state) => state.rent.popularProducts.data);
    const cityUser = useAppSelector((state) => state.user.cityUser);
    const [searchValue, setSearchValue] = useState<string>('');

    const handleChangeRouteToCatalog = () => {
        router.push(`/${cityUser?.slug}`);
    };

    return (
        <>
            <StyledSearchingEmpty>
                <StyledSearchingEmptySmile>
                    <Image fill src="/static/icons/sad_cat_smile.png" alt="sad cat smile" />
                </StyledSearchingEmptySmile>

                <StyledSearchingEmptyTitle>
                    {t(customTitle ? customTitle : 'Мы ничего не нашли по вашему запросу')}
                </StyledSearchingEmptyTitle>

                <StyledSearchingEmptySubtitle>
                    {customSubtitle ? (
                        customSubtitle
                    ) : (
                        <>
                            {t('Попробуйте написать название товара по-другому или сократить запрос')}.
                            <br />
                            {t('Убедитесь, что название бренда и модели написано правильно')}.
                        </>
                    )}
                </StyledSearchingEmptySubtitle>

                <StyledSearchingEmptyInput>
                    <SearchProductsInput
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        classWrapper="bg-white-input"
                    />
                </StyledSearchingEmptyInput>

                <p>{t('Или воспользуйтесь поиском по каталогу')}</p>

                <Button
                    aria-label={t('К каталогу')}
                    onClick={handleChangeRouteToCatalog}
                    variant="dark"
                    fontSize="md"
                    icon={<MenuBookIcon />}
                >
                    {t('К каталогу')}
                </Button>
            </StyledSearchingEmpty>

            <PageSection title={t('Самые популярные 🔥')}>
                <PopularProducts products={popularProducts} />
            </PageSection>
        </>
    );
};
