import Link from 'next/link';
import { NextRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { getQueryPagination, getSlugPagination, removeLastSlugPage } from '@/shared/utils/querify';

import { StyledPaginationDcWrapper } from './styled';

type TypePageLink = 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next';

type TypePagination = 'query' | 'slug';

const itemRenderSeoLinksPagination = (
    router: NextRouter,
    page: number,
    originalElement: React.ReactNode,
    current: number,
    type: TypePageLink,
    isDisabledNavBut?: boolean,
    typePagination?: TypePagination,
): React.ReactNode => {
    const path = removeLastSlugPage(router.asPath, 'page-');

    let slug = typePagination === 'query' ? getQueryPagination(page, path) : getSlugPagination(page, path);

    if (page === 1) {
        slug = removeLastSlugPage(router.asPath, 'page-');
    }

    switch (type) {
        case 'page':
            return page === current ? originalElement : <Link href={slug}>{page}</Link>;

        case 'prev':
        case 'next':
            return (
                !isDisabledNavBut && (
                    <Link href={slug} rel="nofollow">
                        {originalElement}
                    </Link>
                )
            );

        case 'jump-next':
        case 'jump-prev':
            return <span className="no-pagination">•••</span>;

        default:
            return originalElement;
    }
};

type TProps = {
    router: NextRouter;
    isDisabledNavBut: boolean;
    currentPage: number;
    total: number;
    pageSizeItem?: number;
    onChange?: (a: number) => void;
    isSeo?: boolean;
    isShowSizeChanger?: boolean;
    typePagination?: TypePagination;
};

export const PaginationAnt: FC<TProps> = ({
    isDisabledNavBut = false,
    currentPage,
    total,
    pageSizeItem = 5,
    onChange,
    isSeo = false,
    isShowSizeChanger = false,
    router,
    typePagination,
}) => {
    const [pgCurrentPage, setPgCurrentPage] = useState<number>(currentPage);

    useEffect(() => {
        setPgCurrentPage(currentPage);
    }, [currentPage]);

    const handlerChangePagination = (page: number) => {
        if (!isSeo) {
            setPgCurrentPage(page);

            if (onChange) {
                onChange(page);
            }
        }
    };

    if (total <= 1) {
        return <></>;
    }

    return (
        <StyledPaginationDcWrapper
            isDisabledNavBut={isDisabledNavBut}
            showLessItems
            //Responsive={true}
            onChange={handlerChangePagination}
            showSizeChanger={isShowSizeChanger}
            pageSize={pageSizeItem}
            total={total}
            //Simple
            current={pgCurrentPage}
            itemRender={(page, type, originalElement) => {
                if (isSeo) {
                    return itemRenderSeoLinksPagination(
                        router,
                        page,
                        originalElement,
                        pgCurrentPage,
                        type,
                        isDisabledNavBut,
                        typePagination,
                    );
                }

                return originalElement;
            }}
        />
    );
};
