import { styled } from 'styled-components';

import { Button } from '@/shared/ui/button';

export const StyledCategoryProducts = styled.div`
    & .pagination-block {
        margin-top: 24px;
    }
`;

export const StyledShowMoreButton = styled(Button)`
    background: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.dark};
    margin-top: 20px;
    font-weight: 700;
    padding: 12px 40px;
`;

export const StyledCategoryProductLoader = styled(Button)`
    margin-top: 20px;
`;
