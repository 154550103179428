import { Pagination } from 'antd';
import { styled, css } from 'styled-components';

const hidePrevNextBtn = css`
    .ant-pagination-next,
    .ant-pagination-prev {
        display: none;
    }
`;

export const StyledPaginationDcWrapper = styled(Pagination)<{ isDisabledNavBut: boolean }>`
    .ant-pagination-item,
    .ant-pagination-item-active {
        border-color: transparent;
        user-select: none;
    }

    & .ant-pagination-jump-prev,
    & .ant-pagination-jump-next {
        cursor: default !important;
        user-select: none;
    }

    ${(p) => p.isDisabledNavBut && hidePrevNextBtn}
    .ant-pagination-item-before-jump-next {
        display: inline-block;
    }

    .ant-pagination-item {
        min-width: 50px;
        height: 44px;
        font-family: 'Rounded Mplus 1c';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 44px !important;
        color: #1f2547;

        & .no-pagination {
            cursor: default;
        }

        a {
            :hover {
                color: #00d9de;
            }
        }
    }

    .ant-pagination-item-active {
        color: #00d9de;

        a {
            color: #00d9de;
        }
    }
`;
