import React from 'react';

import { ErrorPage } from '@/components/elements/error-page';

import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';

type TProps = {
    statusCode?: number;
    children: React.ReactNode;
};

export const CatchingErrorLayout: React.FC<TProps> = ({ children, statusCode = STATUS_CODE_OK }) => {
    if (statusCode === NOT_FOUND_PAGE) {
        return <ErrorPage errorType={NOT_FOUND_PAGE} />;
    }

    if (statusCode === SERVER_ERROR) {
        return <ErrorPage errorType={SERVER_ERROR} />;
    }

    return <>{children}</>;
};
