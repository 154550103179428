import { styled } from 'styled-components';

import { mq, colBothMiddle } from '@/shared/ui/styled';

export const StyledSearchingEmpty = styled.div`
    ${colBothMiddle};
    max-width: 650px;
    margin: 0 auto 106px;

    & > p {
        font-size: ${({ theme }) => theme.fontSize.sm};
        margin: 32px 0;
    }

    & .bg-white-input {
        .ant-input-suffix,
        .ant-input {
            background: ${({ theme }) => theme.color.secondary};
        }
    }

    ${mq.lt.md} {
        & > p {
            margin: 16px 0;
        }
    }
`;

export const StyledSearchingEmptyInput = styled.div`
    width: 100%;
`;

export const StyledSearchingEmptyTitle = styled.h3`
    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: 700;
    margin: 12px 0;
    text-align: center;
`;

export const StyledSearchingEmptySubtitle = styled.h4`
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 130%;
    margin-bottom: 32px;
    text-align: center;
`;

export const StyledSearchingEmptySmile = styled.span`
    width: 46px;
    height: 48px;
    position: relative;
`;
